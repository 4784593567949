<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'packages' }"
          >Packages</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <v-skeleton-loader
      type="chip"
      transition="fade-transition"
      v-if="loading"
    ></v-skeleton-loader>

    <div v-else>
      <div class="ma-4">
        <div class="display-1 font-weight-bold my-2 text-capitalize">
          <span v-if="projectPackage.milestone">
            {{ projectPackage.milestone.name }}
          </span>
        </div>
        <div class="title  my-2">
          <span v-if="projectPackage.project">
            {{ projectPackage.project.name }}
          </span>
        </div>
        <div class="subtitle-1  my-2">
          by <span class="font-weight-bold"> {{ fullName() }}</span>
        </div>
      </div>

      <div class="mx-4">
        <div class="d-flex justify-space-between">
          <div class="">
            Generated Resource
          </div>
          <!-- <div>
            <v-btn elevation="2" color="primary">Add form</v-btn>
          </div> -->
        </div>

        <v-simple-table>
          <thead>
            <tr>
              <th width="30%" class="text-left">Tasks</th>
              <th width="20%" class="text-left">Status</th>
              <th width="20%" class="text-left">Completion Date</th>
              <th width="10%" class="text-left">Attachments</th>
              <th width="10%" class="text-left">Size</th>
              <!-- <th width="10%" class="text-left">...</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="resource in projectPackage.resources" :key="resource.id">
              <td>{{ stringCapitalize(resource.title) }}</td>
              <td>
                <v-chip
                  class="ma-2"
                  :color="statusColor(resource.status)"
                  :text-color="resource.status === 'not started' ? '' : 'white'"
                >
                  {{ stringCapitalize(resource.status) }}
                </v-chip>
              </td>
              <td>{{ resource.completed_date }}</td>
              <td>{{ resource.attachments.length }}</td>
              <td>{{ resource.attachments_total_size }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { capitalize } from 'lodash'

export default {
  name: 'PackageDetailsPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon
  },

  data() {
    return {
      loading: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline
      }
    }
  },

  mounted() {
    this.getPackage()
  },

  computed: {
    ...mapState({
      projectPackage: state => state.projectPackage.packageDetails
    })
  },

  methods: {
    ...mapActions({
      getPackageDetails: 'projectPackage/fetchPackageDetails'
    }),

    stringCapitalize(str) {
      return capitalize(str)
    },

    async getPackage() {
      this.loading = true
      await this.getPackageDetails(this.$route.params.id)
      this.loading = false
    },

    fullName() {
      return (
        this.stringCapitalize(this.projectPackage.project.owner.first_name) +
        ' ' +
        this.stringCapitalize(this.projectPackage.project.owner.last_name)
      )
    },

    statusColor(status) {
      if (status === 'in progress') return 'primary'

      if (status === 'completed') return 'green'

      return ''
    }
  }
}
</script>
